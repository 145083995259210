import styled from "styled-components"

export const ImageSectionMain = styled.div`
overflow: visible;
top: -1px;
position: relative;

${props => props.theme.max('sm', (props) => (`
  height: 400px;

  .gatsby-image-wrapper {
    position: absolute !important;
    width: 100%;
    height: 100%;
  }
`), props)}

&.cutout-top {
  &:before {
    top: -5px;
    left: 48%;
    width: 52%;
  }

  &.tan-img {
    &:before {
      left: 46%;
      width: 54%;
      height: 16%;
    }
  }
}

&.cutout-bottom {
  &:after {
    bottom: -5px;
    right: 48%;
    width: 52%;
  }

  &.c-intro {
    &:after {
      height: 13%;
    }
  }
}

&.c-intro {
  &.desktop {
    ${props => props.theme.max('mb', () => (`
      display: none;
    `))}
  }
  &.mobile {
    ${props => props.theme.min('mb', () => (`
      display: none;
    `))}
  }
}
`