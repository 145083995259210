import styled from "styled-components"

import { HexagonTitleMain, SectionTitleMain, SectionTextMain, containerSideOffset, col } from "./common.styles"

export const CorporateResponsibilitiesMain = styled.div`
background-color: ${props => props.theme.colors.secondary300};
padding: 144px 0 149px;

${props => props.theme.max('md', () => (`
  padding: 100px 0 100px;
`))}

${props => props.theme.max('sm', () => (`
  padding: 75px 0 44px;
`))}

.top-col {
  ${props => props.theme.max('md', () => (`
    align-items: center;
  `))}

  ${SectionTitleMain} {
    margin-bottom: 42px;
  }
  
  ${HexagonTitleMain} {
    margin-bottom: 77px;
  }
}

.top-col-img {
  ${col(10, 'c')}
  margin-right: auto;
  padding-bottom: 49px;

  ${props => props.theme.max('sm', () => (`
    ${col(100)}
    ${containerSideOffset()}
  `))}
}

.top-col-content {
  ${containerSideOffset('right', 3)}
  ${col(8, 'c')}
  margin-left: auto;
  align-self: flex-end;

  ${props => props.theme.max('lg', () => (`
    ${col(42)}
  `))}
  
  ${props => props.theme.max('md', () => (`
    ${col(57)}
  `))}

  ${props => props.theme.max('sm', () => (`
    ${col(100)}
  `))}
}

${SectionTextMain} {
  max-width: 526px;

  ${props => props.theme.max('sm', () => (`
    max-width: 100%;
  `))}
}

.section-separator {
  height: 1px;
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  ${col(18, 'c')}
  margin: 72px auto 122px auto;

  ${props => props.theme.max('sm', () => (`
    margin: 25px auto 72px auto;
  `))}
}

.center-col {
  ${containerSideOffset()}
  ${SectionTitleMain} {
    margin-bottom: 105px;

    ${props => props.theme.max('mb', () => (`
      margin-bottom: 62px;
    `))}
  }
}

.list-col {
  display: flex;
  flex-wrap: wrap;
  ${containerSideOffset()}
  ${col(8, 'c')}

  ${props => props.theme.max('alg', () => (`
    ${col(40)}
  `))}

  ${props => props.theme.max('mac', () => (`
    ${col(48)}
  `))}

  ${props => props.theme.max('md', () => (`
    ${col(55)}
  `))}

  ${props => props.theme.max('sm', () => (`
    ${col(100)}
    order: 20;
  `))}
}

.side-col {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: auto;
  ${containerSideOffset('right', 4)}
  ${col(25.1)}

  ${props => props.theme.max('md', () => (`
    justify-content: center;
    ${col(45)}
  `))}

  ${props => props.theme.max('sm', () => (`
    ${col(100)}
    order: 10;
    margin-bottom: 60px;
  `))}
}

.list-container {
  display: flex;
  align-items: center;
  padding-bottom: 46px;

  ${props => props.theme.max('md', () => (`
    padding-bottom: 46px;
  `))}
}

.ilm-box {
  display: flex;
  flex-direction: column;
  align-items: center;

  ${props => props.theme.max('mb', () => (`
    padding-bottom: 60px;

    &:last-child {
      padding-bottom: 10px;
    }
  `))}
  
  ${SectionTextMain} {
    text-align: center;

    ${props => props.theme.max('mb', () => (`
      font-size: 22px;
      line-height: 1.63;
    `))}
  }
}

.ilm-img-wrap {
  min-height: 175px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 47px;

  ${props => props.theme.max('sm', () => (`
    margin-bottom: 30px;
  `))}

  ${props => props.theme.max('mb', () => (`
    min-height: 0;
  `))}

  img {
    max-width: 100%;

    ${props => props.theme.max('mb', () => (`
      max-width: 80%;
    `))}
  }
}

.ilm-box-left {
  ${col(4, 'c')}
  ${containerSideOffset()}
  margin-right: auto;

  ${props => props.theme.max('md', () => (`
    ${col(30)}
  `))}

  ${props => props.theme.max('mb', () => (`
    ${col(100)}
  `))}
}

.ilm-box-center {
  ${col(5, 'c')}
  margin-left: auto;
  margin-right: auto;

  ${props => props.theme.max('md', () => (`
    ${col(30)}
  `))}

  ${props => props.theme.max('mb', () => (`
    ${col(100)}
    ${containerSideOffset()}
  `))}
}

.ilm-box-right {
  ${col(4, 'c')}
  ${containerSideOffset('right', 4)} 
  margin-left: auto;

  ${props => props.theme.max('md', () => (`
    ${col(30)}
  `))}
  
  ${props => props.theme.max('mb', () => (`
    ${col(100)}
  `))}
}

.cr-list {
  ${col(48)}
  padding-right: 7.1%;

  ${props => props.theme.max('md', () => (`
    ${col(100)}
    padding-right: 0;
  `))}
}

.cr-list-text {
  line-height: 1.84;

  p {
    margin-bottom: 0;
  }

  ${props => props.theme.max('mb', () => (`
    width: 190px;
  `))}
}

.cr-list-number {
  font-family: ${props => props.theme.fonts.tertiary};
  font-size: 76px;
  color: ${props => props.theme.colors.secondary};
  min-width: 62px;

  ${props => props.theme.max('sm', () => (`
    min-width: 60px;
  `))}
}

.side-col .second-child {
  margin-top: 15.5em;
}

.cr-list-2 {
  padding-right: 3.3%;
  ${props => props.theme.max('md', () => (`
    padding-right: 0;
    padding-top: 0;
  `))}

  .cr-list-number {
    min-width: 70px;

    ${props => props.theme.max('sm', () => (`
      min-width: 60px;
    `))}
  }
}

${SectionTitleMain} {
  ${props => props.theme.max('sm', () => (`
    margin-bottom: 71px;
  `))}
}

.to-animate {
  .ilm-img-wrap {
    opacity: 0;
    transform: scale(0);
    transition: 1200ms;
  }

  .list-container {
    transform: translate(50px, 0);

    ${props => props.theme.max('md', () => (`
      transform: translate(0, 30px);
    `))}
    opacity: 0;
  }
}

.to-animate:not(.is-in-vp) {
  .ilm-img-wrap,
  .list-container {
    transition-delay: 0ms !important;
  }
}

.to-animate.is-in-vp {
  .ilm-img-wrap {
    opacity: 1;
    transform: scale(1);
  }

  .list-container {
    transition: ${800}ms;
    transform: translate(0);
    opacity: 1;
  }
}

.bottom-col {
  ${SectionTitleMain} {
    margin-bottom: 30px;
  }
}

${SectionTitleMain} {
  &.smallest-title {
    ${props => props.theme.max('mb', () => (`
      font-size: 36px;
    `))}
  }
}
`