import styled from "styled-components"

import { SectionTextMain, SectionTitleMain, HexagonTitleMain, col, containerSideOffset } from ".././common.styles"

export const TheBhExperienceMain = styled.div`
  padding-top: 419px;
  padding-bottom: 140px;

  ${props => props.theme.max('lt', () => (`
    padding-top: 250px;
  `))}

  ${props => props.theme.max('md', () => (`
    padding-top: 200px;
  `))}

  ${props => props.theme.max('sm', () => (`
    padding-bottom: 87px;
  `))}

  ${HexagonTitleMain} {
    margin-bottom: 77px;
  }

  ${SectionTitleMain} {
    margin-bottom: .67em;
  }

  .top-col {
    ${containerSideOffset()}
  }

  ${SectionTextMain} {
    max-width: 480px;
  }

  .img-wrap {
    margin-bottom: 102px;

    ${props => props.theme.max('sm', () => (`
      margin-bottom: 4em;
      padding-top: 2em;
    `))}
  }

  .text-col-l {
    ${col(6, 'c')}
    ${containerSideOffset()}
    
    ${props => props.theme.max('lt', () => (`
      ${col(30)}
    `))}

    ${props => props.theme.max('md', () => (`
      ${col(45)}
    `))}
    
    ${props => props.theme.max('sm', () => (`
      ${col(100)}
    `))}
  }
  
  .text-col-r {
    ${containerSideOffset('right', 6)}
    ${col(6, 'c')}
    margin-left: auto;

    ${props => props.theme.max('lt', () => (`
      ${col(30)}
    `))}

    ${props => props.theme.max('md', () => (`
      ${col(45)}
    `))}
    
    ${props => props.theme.max('sm', () => (`
      ${col(100)}
    `))}
  }

  .bh-link-position {
    margin-top: 14px;
  }

  .img-wrap.mobile-only {
    ${containerSideOffset()}
    ${col(100)}
  }
`