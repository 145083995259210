import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { ImageSection } from "../components/image-section"
import { OurTenets } from "../components/culture/our-tenets-section"
import { CommunityInitiatives } from "../components/culture/community-initiatives-section"
import { CorporateResponsibilities } from "../components/culture/corporate-responsibility-section"
import { BannerSection } from "../components/banner-section"
import { TheBhExperience } from "../components/culture/the-bh-experience-section"
import { RevealImage } from "../elements/reveal-image"
import { processHexagonData } from "../helpers/common"

const CultureTemplate = ({
  data: {
    wpgraphql: {
      page: {
        title,
        cultureacf,
      }
    },
  }
}) => {

  const hexagonData = processHexagonData([
    cultureacf.brevanExperience.name,
    cultureacf.ourTenetsSectionName,
    cultureacf.communityInitiatives.name,
    cultureacf.responsibilitySection.name,
    cultureacf.banner.name,
  ]);

  return (
    <Layout>
      <SEO title={title} />

      <TheBhExperience
        columnTextLeft={cultureacf.brevanExperience.columnTextLeft}
        columnTextRight={cultureacf.brevanExperience.columnTextRight}
        hexagonData={hexagonData[0]}
        title={cultureacf.brevanExperience.title}
        linkData={cultureacf.brevanExperience.acfLink}
        image1={cultureacf.brevanExperience.image}
        image1Mobile={cultureacf.brevanExperience.imageMobile}
      />

      <ImageSection
        image={cultureacf.brevanExperience.imageLarge}
        className={"cutout-bottom desktop-only c-intro"}
      />

      <RevealImage
        image={cultureacf.brevanExperience.imageLargeMobile}
        variation={'from-left mobile-only c-intro'}
      />

      <OurTenets
        hexagonData={hexagonData[1]}
        firstTitle={cultureacf.ourTenetsContent[0].title}
        firstText={cultureacf.ourTenetsContent[0].text}
        firstImg={cultureacf.ourTenetsContent[0].image}
        secondTitle={cultureacf.ourTenetsContent[1].title}
        secondText={cultureacf.ourTenetsContent[1].text}
        secondImg={cultureacf.ourTenetsContent[1].image}
        thirdTitle={cultureacf.ourTenetsContent[2].title}
        thirdText={cultureacf.ourTenetsContent[2].text}
        thirdImg={cultureacf.ourTenetsContent[2].image}
        fourthTitle={cultureacf.ourTenetsContent[3].title}
        fourthText={cultureacf.ourTenetsContent[3].text}
        fourthImg={cultureacf.ourTenetsContent[3].image}
      />

      <ImageSection
        image={cultureacf.ourTenetsImageLarge}
        className={"cutout-top desktop-only tan-img"}
      />

      <CommunityInitiatives
        hexagonData={hexagonData[2]}
        title={cultureacf.communityInitiatives.title}
        text={cultureacf.communityInitiatives.text}
        imageCaption={cultureacf.communityInitiatives.imageCaption}
        img={cultureacf.communityInitiatives.image}
      />

      <CorporateResponsibilities
        hexagonData={hexagonData[3]}
        title={cultureacf.responsibilitySection.title}
        text={cultureacf.responsibilitySection.text}
        list={cultureacf.responsibilitySection.list}
        membershipTitle={cultureacf.responsibilitySection.membershipTitle}
        sustainabilityTitle={cultureacf.responsibilitySection.sustainabilityTitle}
        logoList={cultureacf.responsibilitySection.logoList}
        image2={cultureacf.responsibilitySection.image}
        image={cultureacf.responsibilitySection.sustainabilityImage}
      />

      <BannerSection
        hexagonData={hexagonData[4]}
        title={cultureacf.banner.title}
        linkData={cultureacf.banner.acfLink}
        background={cultureacf.banner.image}
        backgroundMobile={cultureacf.banner.imageMobile}
        titleResponsive={[{ breakpoint: 480, charLimit: 12 }]}
      />

    </Layout>
  )
}

export default CultureTemplate

export const homeTemplateQuery = graphql`
  query CultureQuery($id: ID!) {
    wpgraphql {
      page(id: $id) {
        title
        cultureacf {
          ourTenetsSectionName
          ourTenetsContent {
            text
            title
            image {
              mediaItemUrl
              mediaItemId
              modified
              localImageFile {
                childImageSharp {
                  original {
                    src
                    width
                  }
                  fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          ourTenetsImageLarge {
            mediaItemUrl
            mediaItemId
            modified
            localImageFile {
              childImageSharp {
                original {
                  src
                  width
                }
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          communityInitiatives {
            name
            title
            text
            imageCaption
            image {
              mediaItemUrl
              mediaItemId
              modified
              localImageFile {
                childImageSharp {
                  original {
                    src
                    width
                  }
                  fluid(maxWidth: 750) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          brevanExperience {
            columnTextLeft
            columnTextRight
            name
            title
            image {
              mediaItemUrl
              mediaItemId
              modified
              localImageFile {
                childImageSharp {
                  original {
                    src
                    width
                  }
                  fluid(maxWidth: 1600) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            imageMobile {
              mediaItemUrl
              mediaItemId
              modified
              localImageFile {
                childImageSharp {
                  original {
                    src
                    width
                  }
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            imageLarge {
              mediaItemUrl
              mediaItemId
              modified
              localImageFile {
                childImageSharp {
                  original {
                    src
                    width
                  }
                  fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            imageLargeMobile {
              mediaItemUrl
              mediaItemId
              modified
              localImageFile {
                childImageSharp {
                  original {
                    src
                    width
                  }
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            acfLink {
              externalUrl
              fieldGroupName
              linkText
              type
              openInNewWindow
              linkToPage {
                ... on WPGraphQL_Page {
                  uri
                }
              }
            }
          }
          responsibilitySection {
            text
            title
            name
            membershipTitle 
            sustainabilityTitle
            image {
              mediaItemUrl
              mediaItemId
              modified
              localImageFile {
                childImageSharp {
                  original {
                    src
                    width
                  }
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            sustainabilityImage {
              mediaItemUrl
              mediaItemId
              modified
              localImageFile {
                childImageSharp {
                  original {
                    src
                    width
                  }
                  fluid(maxWidth: 480) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            list {
              text
            }
            logoList {
              text
              image {
                mediaItemUrl
                mediaItemId
                modified
                localImageFile {
                  childImageSharp {
                    original {
                      src
                      width
                    }
                  }
                }
              }
            }
            
          }
          banner {
            name
            title
            image {
              mediaItemUrl
              mediaItemId
              modified
              localImageFile {
                childImageSharp {
                  original {
                    src
                    width
                  }
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            imageMobile {
              mediaItemUrl
              mediaItemId
              modified
              localImageFile {
                childImageSharp {
                  original {
                    src
                    width
                  }
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            acfLink {
              externalUrl
              fieldGroupName
              linkText
              type
              openInNewWindow
              linkToPage {
                ... on WPGraphQL_Page {
                  uri
                }
              }
            }
          }
        }
      }
    }
  }
`