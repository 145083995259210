import styled from "styled-components"

import { HexagonTitleMain, RevealImageWrap, SectionTextMain, SectionTitleMain, containerSideOffset, col } from "./common.styles"

export const OurTenetsMain = styled.div`
padding: 20px 0 88px;

${props => props.theme.max('sm', () => (`
  padding: 42px 0 68px;
`))}

${props => props.theme.max('mb', () => (`
  padding-bottom: 30px;
`))}

.main-col,
.side-col {
  padding-top: 60px;

  ${props => props.theme.max('mb', () => (`
    padding-top: 30px;
  `))}
}

.main-col {
  ${containerSideOffset()}
  ${col(8, 'c')}

  ${props => props.theme.max('md', () => (`
    ${col(45)}
  `))}
}

.side-col {
  ${containerSideOffset('right', 3.45)}
  ${col(27)}
  margin-left: auto;

  ${props => props.theme.max('md', () => (`
    ${col(54)}
  `))}
}

${SectionTitleMain} {
  margin-bottom: .43em;

  ${props => props.theme.max('mb', () => (`
    margin-bottom: 1.03em;
  `))}
}

${SectionTextMain} {
  max-width: 455px;
}

.t-item-2 {
  padding-top: 322px;

  ${props => props.theme.max('sm', () => (`
    padding-top: 27px;
    padding-bottom: 20px;
  `))}

  ${props => props.theme.max('mb', () => (`
    order: 30;
    ${col(100)}
  `))}
}

.t-item-3 {
  padding-top: 0;
  margin-top: -98px;

  ${props => props.theme.max('sm', () => (`
    margin-top: 0;
 
    ${col(46)}
  `))}

  ${props => props.theme.max('mb', () => (`
    order: 20;
    ${col(100)}
    padding-top: 30px;
    padding-bottom: 70px;
  `))}

  ${RevealImageWrap} {
    max-width: 516px;
  }
}

.t-item-4 {
  padding-top: 0;
  
  ${props => props.theme.max('sm', () => (`
    padding-left: 0;
    padding-top: 110px;
  `))}
  
  ${props => props.theme.max('mb', () => (`
    ${col(100)}
    ${containerSideOffset()}
    order: 40;
    padding-top: 10px;
  `))}
  
  ${RevealImageWrap} {
    ${props => props.theme.min('mb', () => (`
      max-width: 400px;
    `))}
  }
}

.t-item-5 {
  padding-top: 105px;
  
  ${props => props.theme.max('md', () => (`
    padding-top: 100px;
  `))}

  ${props => props.theme.max('mb', () => (`
    order: 50;
  `))}

  ${SectionTextMain} {
    margin-bottom: 48px;
    
    ${props => props.theme.max('sm', () => (`
      margin-bottom: 0;
    `))}
  }
  
  ${RevealImageWrap} {
    max-width: 320px;
  }
}

.t-item-6 {
  padding-top: 0;
  margin-top: -43px;
  
  ${props => props.theme.max('md', () => (`
    padding-top: 40px;
    margin-top: 0;
  `))}

  ${props => props.theme.max('mb', () => (`
    padding-top: 95px;
    order: 70;
  `))}

  ${SectionTextMain} {
    margin-bottom: 48px;
  }
}

.t-item-7 {
  ${props => props.theme.max('sm', () => (`
    padding-top: 30px;
    ${col(50)}
  `))}
  
  ${props => props.theme.max('mb', () => (`
    order: 60;
    ${col(100)}
  `))}
}

.t-item-8 {
  padding-top: 120px;

  ${props => props.theme.max('sm', () => (`
    padding-top: 80px;
    padding-left: 0;
    ${col(50)}
  `))}

  ${props => props.theme.max('mb', () => (`
    padding-top: 10px;
    order: 80;
    ${col(100)}
    ${containerSideOffset()}
  `))}

  ${RevealImageWrap} {
    max-width: 516px;
  }
}

.t-item-1 {
  padding-bottom: 118px;
  
  ${props => props.theme.max('sm', () => (`
    padding-bottom: 0;
  `))}

  ${props => props.theme.max('mb', () => (`
    order: 10;
  `))}
}

.t-item-1,
.t-item-2,
.t-item-5,
.t-item-6 {
  ${props => props.theme.max('sm', () => (`
    ${col(100)}
  `))}
}

${HexagonTitleMain} {
  margin-bottom: 71px;
  ${props => props.theme.max('sm', () => (`
    margin-bottom: 50px;
  `))}
}
`