import styled from "styled-components"

import { paddingMobile, HexagonTitleMain, SectionTitleMain, SectionTextMain, containerSideOffset, col } from "./common.styles"

export const CommunityInitiativesMain = styled.div`
padding: 170px 0 172px;

${props => props.theme.max('sm', () => (`
  padding: 82px 0 92px;
`))}

${props => props.theme.max('mb', () => (`
  padding: 44px 0 104px;
`))}

${HexagonTitleMain} {
  padding-top: 34px;
  margin-bottom: 77px;
}

.main-col {
  ${containerSideOffset()}
  ${col(7, 'c')}

  ${props => props.theme.max('lg', () => (`
    margin-left: 3%;
    ${col(38)}
  `))}

  ${props => props.theme.max('md', () => (`
    margin-left: 0;
    ${col(55)}
  `))}

  ${props => props.theme.max('sm', () => (`
    ${col(100)}
  `))}

  ${props => props.theme.max('mb', () => (`
    padding-bottom: 20px;
  `))}
}

.side-col {
  margin-left: auto;
  ${containerSideOffset('right', 1.7)}
  ${col(9.35, 'c')}
  
  ${props => props.theme.max('md', () => (`
    ${col(44)}
  `))}
  
  ${props => props.theme.max('sm', () => (`
    ${col(100)}
  `))}
}

${SectionTitleMain} {
  margin-bottom: .5em;

  ${props => props.theme.max('sm', () => (`
    margin-bottom: 0.8em;
  `))}
}

${SectionTextMain} {
  &.large {
    max-width: 550px;
    
    ${props => props.theme.max('sm', () => (`
      max-width: 100%;
    `))}
  }
}

.img-caption {
  font-size: 11px;
  text-align: center;
  height: 60px;
  padding-top: 34px;
  max-width: 300px;
  margin: 0 auto;

  span {
    opacity: .5;
  }
}

.flex-wrap {
  ${props => props.theme.max('md', () => (`
    align-items: center;
  `))}
}

.second-child {
  margin-left: ${paddingMobile * -1}px;
  margin-right: ${paddingMobile * -1}px;
}
`