import React from "react"
import Img from "gatsby-image"

import { unoptimzeGatsbyImageSource } from "../helpers/common"

import { ImageSectionMain } from "../styles/image.styles"

export const ImageSection = ({ className, image }) => {
  return (
    <ImageSectionMain className={className}>
      <Img fluid={unoptimzeGatsbyImageSource(image)} />
    </ImageSectionMain>
  )
}